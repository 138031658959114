/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/** Variables **********************************************/
$base-font-family:              Lato, sans-serif;
$base-line-height:              1.5em;
$horizontal-spacing-unit:       50px;
$vertical-spacing-unit:         40px;
$nav-height:                    56px;

/* portfolio tinkering */
$img_spacing: 5px; 
$img_height: 275px;
$caption_font_size: 12px;
$caption_color: #aaa;

/* Set theme color *************************/
$theme-color:   $colour-theme;


/************************************************************/

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 100;
    color: $colour-text;
    background-color: $colour-background;
    -webkit-text-size-adjust: 100%;
}

.brand-text {
	color: $theme-color;
	display: inline;
}

.projects_container {
	/*overflow-y: scroll;*/
	width: 100%;
}

.head_name {
	color: $colour-text-title;
    font-size: 1.75em;
    line-height: 1;
    letter-spacing: 3px;
	font-weight: 400;
	font-size: $base-font-size * 2;
	padding: 0%;
}

.head_role {
	color: $colour-theme;
    font-size: 1.75em;
    line-height: 1;
    letter-spacing: 3px;
	font-weight: 400;
    font-size: $base-font-size * 1.5;
}

@media (max-width : 1020px) {
	.head_name {
		color: #000;
		font-size: 1.75em;
		line-height: 1;
		letter-spacing: 3px;
		font-weight: 400;
		font-size: $base-font-size * 1;
		padding: 0%;
		padding-top: 5%;
	}
	
	.head_role {
		color: $theme-color;
		font-size: 1.75em;
		line-height: 1;
		letter-spacing: 3px;
		font-weight: 400;
		font-size: $base-font-size * 0.75;
	}
}

.blogdate {
    font-size: 12px;
    display: inline;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $vertical-spacing-unit / 2;
}

.alignright {
    float: right;
}


hr{
    /* Inset, by Dan Eden */
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

}


/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $horizontal-spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
}


/**
 * Links
 */
a {
    color: $colour-text-link;
    text-decoration: none;
    /*
    &:visited {
        color: darken($colour-theme, 15%); 
    }
    */
    &:hover {
        color: $theme-color;
        text-decoration: none;
    }
}

article a {
    color: $theme-color;
    font-weight: 100;

    &:hover {
        text-decoration: none;
    }
}

a.portfolio-content {
    color: $colour-theme;
}


/**
 * Blockquotes
 */
blockquote {
    color: $colour-text;
    border-left: 10px solid $grey-color-light;
    padding-left: $horizontal-spacing-unit / 2;
    font-size: 18px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border-radius: 3px;
    background-color: $grey-color-light;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */ 

.wrapper {
	height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: $horizontal-spacing-unit;
    padding-left: $horizontal-spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(800px - (#{$horizontal-spacing-unit}));
        max-width:         calc(800px - (#{$horizontal-spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}


/**
 * Clearfix
 */
%clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


iframe {
    margin: 0 auto;
    display: block;
    border-style:none;
}

.container {
    width: 100%;
    height: 75%;
}
.container_row {
    display: grid;
}
.layer1, .layer2, .layer3, .layer4 {
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
}
.eye {
    position: relative;
    display: inline-block;
    z-index: 0;
    border-radius: 50%;
    width: 5%;
    height: 6.6%;
    left: 49%;
    top: 22%;
    transform: translate(-50%, -50%);
    background: #FFF;
}
.eye:after {
    position: absolute;
    z-index: 0;
    bottom: 25%;
    right: -8%;
    width: 75%;
    height: 75%;
    background: #000;
    border-radius: 50%;
    content: " ";
}
.eyer {
    position: relative;
    display: inline-block;
    z-index: 0;
    border-radius: 50%;
    width: 5%;
    height: 6.6%;
    left: 54%;
    top: 24%;
    transform: translate(-50%, -50%);
    background: #FFF;
}
.eyer:after {
    position: absolute;
    z-index: 0;
    bottom: 25%;
    right: -8%;
    width: 75%;
    height: 75%;
    background: #000;
    border-radius: 50%;
    content: " ";
}