
.site-inner {
	margin: 5%;
	padding: 0%;
	left:12.5%;
	top:-4%;
	width: 65%;
	height: auto;
	position: absolute;
	background-color: $colour-foreground;
}
@media (max-width : 1400px) {
	.site-inner {
		margin: 0%;
		margin-top: 5%;
		left:5%;
		width: 90%;
	}
}

/**
 * Site header   **********************************************************
 */
.site-header {
	background-color: $colour-foreground;
	opacity: 0.75;
	width: 100%;
	height:128px;
	z-index: 50;
}

.site-header-table {
	margin: 0%;
	padding: 0%;
	width: 100%;
	height: inherit;
	top: 0%;
	z-index: 500;
}

.site-title {
	font-size: 20px;
	line-height: $nav-height;
	letter-spacing: -1px;
	margin-bottom: 0;
	&:hover {
		text-decoration: none;
		color: $colour-text-hover;
	}
}

.site-nav {
	float: right;
	line-height: $nav-height;
	.page-link{
		color: $colour-text;
		line-height: $base-line-height;
				// Gaps between nav items, but not on the first one
		&:not(:first-child) {
			margin-left: 10px;
		}
	}
	@include media-query($on-palm) {
	position: fixed;
	top: 0px;
	right: 10px;
	text-align: right;
	&:hover .trigger {
	display: block;
	padding-bottom: 5px;
			}
	.page-link {
	display: line;
			}
		}
}

/* Style the navigation menu */
.topnav {
	position: absolute;
	top: 32px;
	right: 5%;
	display: none;
	z-index: 100;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #myLinks {
	display: none;
	overflow: hidden;
	background-color: #333;
	position: sticky;
  	z-index: 100;
}

/* Style navigation menu links */
.topnav a {
	color: white;
	padding: 16px 16px;
	text-decoration: none;
	font-size: 17px;
	display: block;
}

/* Style the hamburger menu */
.topnav a.icon {
	background: #ddd;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
	background-color: #ddd;
	color: black;
}

/* Style the active link (or home/logo) */
.active {
	background-color: $colour-theme;
	color: white;
}
@media (max-width : 660px) {
	.site-nav {
		display: none;
	}
	.topnav {
		background-color: #333;
		position: absolute;
		right: 5%;
		display: block;
		z-index: 100;
	}
}

.header-bar{
    left: 0px;
    top: 0px;
    position: relative;
    font-size: 20px;
    display: block;
    opacity: 0.75;
    text-align: center;
    padding-top: 25px;
    line-height: 3em;
    z-index: 25;
    h1{
        color: $theme-color;
        font-size:75px;
    }
    h2{
        font-size:25px;
	}
}

/**
 * Site footer  **********************************************************
 */
.site-footer {
	font-size: 14px;
	text-align: center;
	padding: 2px;
	background-color: $colour-foreground;
	color: $colour-text;
	left:0px;
	bottom:0px;
}
.site-footer p {
    margin-bottom: 0; // Remove margin set in _sass/_base.scss for vertical rythm
}

.site-footer-below {
	height: 64px;
	background-color: $colour-background;
}

/**
 * Pagination   **********************************************************
 */
.pagination{
	max-width: -webkit-calc(800px - (#{$horizontal-spacing-unit} * 2));
	text-align: center;
	width: 100%;
	height: 100%;
	margin: 12px;
	position: relative;
	font-size: 32px;
}
.paginationicon {
font-size: 50px;
a {
color: $theme-color;
    }
}

/**
 * Page content   **********************************************************
 */
.page-content {
padding: 0; /* VERTICAL PADDING FOR TITLE ON EVERY PAGE */
width: 100%;
}
.page-heading {
font-size: 20px;
}
.post-list {
margin: 0px 0;
list-style: none;
    > li {
margin-bottom: $vertical-spacing-unit;
    }
}
.contacticon {
font-size: 60px;
display:block;
margin: 10px;
}
.center{
text-align: center;
max-width: 100%;
}

/**
 * Posts  **********************************************************
 */
.post-header {
margin-bottom: $vertical-spacing-unit;
}
.post-title {
font-size: 42px;
letter-spacing: -1px;
line-height: 1;
@include media-query($on-laptop) {
font-size: 36px;
    }
@include media-query($on-palm) {
	font-size: 56px;
}
}
.post-content {
h2 {
font-size: 42px;
@include media-query($on-laptop) {
font-size: 28px;
        }
		@include media-query($on-palm) {
			font-size: 56px;
		}
    }
h3 {
font-size: 30px;
@include media-query($on-laptop) {
font-size: 22px;
        }
		@include media-query($on-palm) {
			font-size: 42px;
		}
    }
h4 {
font-size: 20px;
@include media-query($on-laptop) {
font-size: 18px;
        }
		@include media-query($on-palm) {
			font-size: 30px;
		}
    }
}
.post-meta {
	font-size: $small-font-size;
	color: $grey-color;
	margin-bottom: 0px; 
}
.post-link {
	display: block;
	font-size: 42px;
}

/** 
 * Portfolio grid **********************************************************
*/
.portfolio-content {
	font-size: $portfolio-font-size;
	line-height: $portfolio-line-height;
	color: $colour-text;
	@include media-query($on-palm) {
		font-size: 24px;
	}
}

.portfolio-table{
	width: 100%;
	height: 100%;
	display: inline-block;
}
.portfolio-cover-cell {
	padding: 0%;
	width: 60%;
}
.side-crop {
	max-width: 767px;
	height: 62%;
	overflow: hidden;
}
.side-crop img {
	max-width: initial;
	width: 100%;
}
.portfolio-info-cell {
	padding: 2%;
	vertical-align: top;
	width: 30%;
}
.portfolio-cover-break {
	line-height: 3;
}
@media (max-width : 1700px) {
	.side-crop img {
		width: 100%;
	}
	.portfolio-cover-cell {
		padding: 0%;
		width: 100%;
		display: inline-block;
	}
	.portfolio-info-cell {
		padding: 2%;
		vertical-align: top;
		width: 100%;
	}
}

// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}
.clearfix:after {
	clear: both;
}

.feature_project {
	width: 100%;
	height: 30%;
	vertical-align: middle;
	box-sizing: border-box;
	padding: 10px;
}
.feature_img a span {
	display: inline-block;
	position: absolute;
	left: 0;
	bottom: 0;
	height: auto;
	width: auto;

	background: $colour-feature-overlay-background;
	color: $colour-feature-overlay-text;

	padding: 16px;
	padding-bottom: 0%;
	text-align: left;
	font-size: 60%;
	font-weight: 600;
	line-height: 16px;
}
.feature_img{
	overflow: hidden;
	position: relative;
	width: 100%;
	display: block;
	padding-top:0%;
}
.feature_img_desktop{
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	display: block;
	padding-top:0%;
	position: relative;
}
.feature_img_mobile{
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	display: block;
	padding-top:0%;
	position: relative;
}

.project {
	border: 0;
	float: left;
	width: 30%;
	margin-left: 1%;
	margin-right: 2%;
	margin-bottom: 32px;
}
.project.left {
	clear: left;
}
.project.mid {
	clear: none;
	margin-left: 2%;
	margin-right: 0%;
}
.project.right {
	clear: right;
	margin-right: 1%;
}

@media (max-width : 720px) {
    .project {
		width: 99%;
		display:inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		padding: 10px;
	}
	.feature_img_desktop {
		display: none;
	}
}
@media (min-width : 721px) {
	.feature_img_mobile {
		display: none;
	}
}
.thumbnail{
	width: 100%;
	overflow: hidden;
}
.thumbnail img{
	width: 100%;
	height: 75%;
	padding-top:0%;
	position: relative;
}
.thumbnail a{
	float: left;
	position: relative;
	width: 100%;
	height: auto;
	text-align: center;
	font-size: 8px;
}
@media (min-width : 600px) {
	.thumbnail a{
		font-size: 8px;
	}
}
@media (min-width : 720px) {
	.thumbnail a{
		font-size: 8px;
	}
}
@media (min-width : 1070px) {
	.thumbnail a{
		font-size: 8px;
	}
}
@media (min-width : 1330px) {
	.thumbnail a{
		font-size: 8px;
	}
}

.overlay_parent {
	position: relative;
	width: 100%;
	height: 100%;
}
.feature_overlay {
	width: 40%;
    height: 40%;
    position: absolute;
	display:inline-block;
    bottom: 0;
    left: 0;
	z-index: 9;
	text-align: left;
	font-size: 24px;
	background: $colour-feature-overlay-background;
}
.thumnail_overlay {
	width: 100%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
	z-index: 9;
}
.thumnail_overlay img {
    position:absolute;
	width: 20%;
	height: auto;
}
.thumnail_overlay_bottomright {
	right: 2%;
	bottom: 2%;
}
.thumnail_overlay_topleft {
	left: 2%;
	top: 2%;
}
.thumnail_overlay_topleft_banner{
	left: 0;
	top: 0;
	max-width: 14%;
}

/* Check boxes */

.check-containing-table-top  {
	display: table;
	margin-left: 1.5%;
	margin-right: 1.5%;
    width: 97%;
    height: 24px;
	margin-bottom: -24px;
}
.check-label {
	display: inline-block;
	font-size: 16px;
	margin-bottom: -24px;
}
.check-center-content {
	
}
.check-containing-table {
	display: table;
	margin-left: 1.5%;
	margin-right: 1.5%;
    width: 97%;
    height: 24px;
	margin-top: -8px;
	margin-bottom: 0px;
}
.check-containing-table-bottom {
	display: table;
	margin-left: 1.5%;
	margin-right: 1.5%;
    width: 97%;
	height: 24px;
}
.check-center-align {
	padding: 0px;
	width: 100%;
    text-align: center;
    vertical-align: middle;
	font-size: 16px;
}
.check-right-align {
	padding: 0px;
	width: 100%;
    text-align: right;
    vertical-align: middle;
	font-size: 16px;
}
@media (max-width : 1400px) {
	.check-containing-table-top  {
		margin-bottom: 0px;
	}
	.check-containing-table  {
		margin-bottom: 0px;
	}
}
@media (max-width : 720px) {
	.check-containing-table-top  {
		display: none;
	}
	.check-containing-table  {
		display: none;
	}
	.check-containing-table-bottom  {
		display: none;
	}
}

/** 
 * Portfolio pages **********************************************************
*/
.blankbox{
	background: $theme-color;
}
.img_row{
	height: $img_height;
	width: 100%;
	overflow: hidden;
	box-sizing:border-box;
	padding: $img_spacing;
}
.col{ 
	width: 100%;
	height: 100%;
	float: left;
	object-fit: cover;
	box-sizing:border-box;
	padding: $img_spacing;
}
.right{
	float: right;
}
.one {
	width:33.33%;
}
.two {
	width: 66.66%;
}
.three{
	width: 100%;
}
.caption{
	height: 100%;
	color: $caption_color;
	text-align: center;
	vertical-align: middle;
	font-size: $caption_font_size;
}

#CharityPie{
	background-color: $colour-foreground;
	color: $colour-text;
	width: 100%;
	height: 384px;
}